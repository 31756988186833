import React, { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Overlay from 'react-bootstrap/Overlay';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

import facebook from './facebook.png';
import './styles.scss';

export default function Footer() {
  const [showEdenred, setShowEdenred] = useState(false);
  const edenred = useRef(null);

  const [showSmartum, setShowSmartum] = useState(false);
  const smartum = useRef(null);

  const [showEPassi, setShowEPassi] = useState(false);
  const epassi = useRef(null);

  return (
    <Container fluid className="footer">
      <Container>
        <Row className="pt-3 text-center align-items-center">
          <Col md={4} className="">
            <button
              className="payment-method-btn"
              onClick={() => {
                setShowSmartum(false);
                setShowEPassi(false);
                setShowEdenred(!showEdenred);
              }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/66/Edenred_Logo.svg"
                alt="Edenred Pay"
                width="120"
                ref={edenred}
              />
            </button>
            <Overlay target={edenred.current} show={showEdenred} placement="top">
              {(props) => (
                <Tooltip id="edenred-overlay" {...props}>
                  Edenred Pay käy maksutapana!
                </Tooltip>
              )}
            </Overlay>
          </Col>

          <Col md={4} className="mb-3">
            <button
              className="payment-method-btn"
              onClick={() => {
                setShowSmartum(false);
                setShowEdenred(false);
                setShowEPassi(!showEPassi);
              }}
            >
              <img
                src="https://www.epassi.fi/hubfs/Epassi%20Logo%20Primary%20Color%20RGB-1.png"
                alt="Epassi"
                width="120"
                ref={epassi}
              />
            </button>
            <Overlay target={epassi.current} show={showEPassi} placement="top">
              {(props) => (
                <Tooltip id="epassi-overlay" {...props}>
                  Epassi käy maksutapana!
                </Tooltip>
              )}
            </Overlay>
          </Col>

          <Col md={4} className="mb-3">
            <button
              className="payment-method-btn"
              onClick={() => {
                setShowEdenred(false);
                setShowEPassi(false);
                setShowSmartum(!showSmartum);
              }}
            >
              <img
                src="https://badges.smartum.fi/button/color.svg?width=120&height=30"
                alt="Smartum"
                ref={smartum}
              />
            </button>
            <Overlay target={smartum.current} show={showSmartum} placement="top">
              {(props) => (
                <Tooltip id="smartum-overlay" {...props}>
                  Smartum käy maksutapana!
                </Tooltip>
              )}
            </Overlay>
          </Col>
        </Row>

        <Row className="text-center">
          <Col className="mt-2 mb-2">
            <a href="https://www.facebook.com/share/dTec9TtPnUV1wT7Y/">
              <img src={facebook} alt="Facebook" className="" width="42" />
            </a>
          </Col>
        </Row>

        <Row className="text-center">
          <Col className="mt-2 mb-2">
            <a href="/tietosuojaseloste">Tietosuojaseloste</a><br />
            &copy; 2024 Tmi Annette Jaakola
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
